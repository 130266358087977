.contenedor-titulo-MAd {
  display: flex;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  line-height: 2.7;
  margin-top: 110px;
}
.contenedor-sub-MAd {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
.contenedor-tit-MAd {
  grid-column: 1/-1;
  grid-row: 4;
  height: 7vh;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Quicksand;
}
.contenedor-imagecard-MAd {
  grid-column: 1/-1;
  grid-row: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor-titulo-MAd {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    line-height: 1.7;
    margin-top: 110px;
    width: 90%;
  }
  .contenedor-sub-MAd {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    justify-content: center;
    width: 80%;
    text-align: center;
    margin-top: 15px;
  }
  .contenedor-tit-MAd {
    grid-column: 1/-1;
    grid-row: 4;
    height: auto;
    text-align: center;
    font-size: 33px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Quicksand;
  }
  .contenedor-imagecard-MAd {
    grid-column: 1/-1;
    grid-row: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    flex-direction: column;
  }
}
