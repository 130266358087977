.tituloServ {
  font-size: 36px;
  font-weight: bold;
  font-family: "Quicksand";
  color: #282828;
}
.textoServ {
  font-weight: 100;
  height: 5vh;
  text-align: center;
  font-size: 20px;
  color: #494949;
}
.contenedorBotonVer {
  height: 50px;
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #494949; */
}
.botonVer {
  background-color: #dd9933;
  border: none;
  color: #fff;
  height: 46px;
  width: 96px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  font-weight: bold;
}
.botonVer:hover {
  background-color: #fff;
  border: 1px solid #dd9933; /* Agregamos un borde azul */
  color: #dd9933;
}
/****...........................texto img ....................******/

/* //todo....Menu expandible.......................................... */
.contenedormenu {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.expandable-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.expand-button {
  border: none;
  font-family: Quicksand;
  font-size: 20px;
  height: 45px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 64%;
  justify-content: flex-start;
  font-weight: bold;
  border: 1px solid #eeeeee;
  color: #666666;
  background-color: #f8f8f8;
  /* background-color: red; */
}
.divBoton {
  width: 100%;
}
.expanded-content {
  background-color: #f8f8f8;
  border: 1px solid #eeeeee;
  padding: 10px;
  border-radius: 2px;
  text-align: left;
  width: 62.9%;
  color: #494949;
  font-size: 14px;
  line-height: 25px;
}

/* .iconoflecha {
  position: absolute;
  left: 0;
  color: #dd9933;
} */
/* //todo..............................INTEGRACION GPS */

.contenedorINT {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Quicksand;
}
.contenedorTit {
  font-size: 26px;
  font-weight: 800;
  width: 100%;
}
.contenedorTex {
  margin-top: 10px;
  font-size: 19px;
  line-height: 31px;
}
.contenedor-tamañoimg-dispositivos {
  grid-column: 1/-1;
  grid-row: 2;
  background-image: url("../assets/img/ImgDispositivos/gps.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2000px;
  height: 325px;
  display: flex;
  justify-content: center;
}
.contenedor-tamañoimg-dispositivos2 {
  grid-column: 1/-1;
  grid-row: 6;
  background-image: url("../assets/img/ImgDispositivos/gps.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  height: 378px;
  min-height: 70px;
  display: flex;
  justify-content: center;
}
.contenedor-titulo-serv {
  grid-column: 1/-1;
  grid-row: 4;
  height: 7vh;
  text-align: center;
}
.contenedor-desplegable1 {
  grid-column: 1/-1;
  grid-row: 11;
  min-height: 5vh;
  width: 100%;
}
.contenedor-tamañoimg-dispositivos3 {
  grid-column: 1/-1;
  grid-row: 15;
  background-image: url("../assets/img/ImgDispositivos/gps3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  height: 378px;
  min-height: 70px;
  display: flex;
  justify-content: center;
}
.contenedor-tamañoimg-dispositivos4 {
  grid-column: 1/-1;
  grid-row: 21;
  background-image: url("../assets/img/ImgDispositivos/gps4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  height: 378px;
  min-height: 70px;
  display: flex;
  justify-content: center;
}
.contenedor-tamañoimg-dispositivos5 {
  grid-column: 1/-1;
  grid-row: 25;
  background-image: url("../assets/img/ImgDispositivos/gps5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  height: 378px;
  min-height: 70px;
  display: flex;
  justify-content: center;
}
.contenedor-tamañoimg-dispositivos6 {
  grid-column: 1/-1;
  grid-row: 31;
  background-image: url("../assets/img/ImgDispositivos/gps6.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  height: 378px;
  min-height: 70px;
  display: flex;
  justify-content: center;
}
.contenedor-img-integraciongps {
  grid-column: 2/8;
  grid-row: 37;
  background-image: url("../assets/img/ImgDispositivos/img7.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  height: 500px;
  min-height: 70px;
  display: flex;
  justify-content: center;
}
.contenedor-text-integraciongps {
  grid-column: 7/-1;
  grid-row: 37;
  display: flex;
  justify-content: flex-start;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */
@media (max-width: 950px) {
  .contenedor-tamañoimg-dispositivos {
    grid-column: 1/-1;
    grid-row: 2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 500px;
    height: 325px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .contenedor-tamañoimg-dispositivos2 {
    grid-column: 1/-1;
    grid-row: 6;
    background-image: url("../assets/img/ImgDispositivos/Precinto-GPS.jpg");
    background-position: center;
    background-repeat: cover;
    background-size: 330px;
    height: 30vh;
    min-height: 70px;
    display: flex;
    justify-content: center;
  }
  .tituloServ {
    font-size: 35px;
    height: auto;
    font-weight: bold;
    font-family: "Quicksand";
    color: #282828;
    width: 70%;
  }
  .textoServ {
    font-weight: 100;
    height: auto;
    text-align: center;
    font-size: 20px;
    color: #494949;
    padding-top: 20px;
  }
  .contenedor-titulo-serv {
    grid-column: 1/-1;
    grid-row: 4;
    height: auto;
    display: flex;
    justify-content: center;
  }
  .expand-button {
    border: none;
    font-family: Quicksand;
    font-size: 20px;
    height: auto;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 83%;
    justify-content: flex-start;
    font-weight: bold;
    border: 1px solid #eeeeee;
    color: #666666;
    background-color: #f8f8f8;
    padding: 10px;
  }
  .expanded-content {
    background-color: #f8f8f8;
    border: 1px solid #eeeeee;
    padding: 10px;
    border-radius: 2px;
    text-align: left;
    width: 78%;
    color: #494949;
    font-size: 14px;
    line-height: 25px;
  }
  .contenedor-tamañoimg-dispositivos3 {
    grid-column: 1/-1;
    grid-row: 15;
    background-image: url("../assets/img/ImgDispositivos/Candado-GPS.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 330px;
    height: 30vh;
    min-height: 70px;
    display: flex;
    justify-content: center;
  }
  .contenedor-tamañoimg-dispositivos4 {
    grid-column: 1/-1;
    grid-row: 21;
    background-image: url("../assets/img/ImgDispositivos/Senuelo-GPS-celular.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 330px;
    height: 30vh;
    min-height: 70px;
    display: flex;
    justify-content: center;
  }
  .contenedor-tamañoimg-dispositivos5 {
    grid-column: 1/-1;
    grid-row: 25;
    background-image: url("../assets/img/ImgDispositivos/Soga-Electronica-celular.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 330px;
    height: 30vh;
    min-height: 70px;
    display: flex;
    justify-content: center;
  }
  .contenedor-tamañoimg-dispositivos6 {
    grid-column: 1/-1;
    grid-row: 31;
    background-image: url("../assets/img/ImgDispositivos/Termografo-GPS-celular.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 330px;
    height: 30vh;
    min-height: 70px;
    display: flex;
    justify-content: center;
  }
  .contenedor-img-integraciongps {
    grid-column: 1/-1;
    grid-row: 37;
    background-image: url("../assets/img/ImgDispositivos/img7.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 330px;
    height: 30vh;
    min-height: 70px;
    display: flex;
    justify-content: center;
  }
  .contenedor-text-integraciongps {
    grid-column: 2/-1;
    padding-top: 20px;
    grid-row: 38;
    display: flex;
    justify-content: flex-start;
  }
}
