.tituloPorque {
  font-size: 26px;
  color: #282828;
  font-weight: bold;
}
.contenidoPorque {
  text-align: center;
  /* font-family: "Times New Roman", Times, serif; */
  width: 240px;
}
.posicion {
  font-size: 40px;
  font-weight: lighter;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.primerP {
  font-size: 42px;
  color: #282828;
  font-weight: 700;
  font-family: Quicksand;
  height: 60px;
}

.segundoP {
  font-size: 16px;
  color: #282828;
  font-weight: bold;
}
.tercerP {
  font-size: 14px;
  color: #888888;
}
.titulo-contenedor-gris {
  grid-column: 1/7;
  grid-row: 9;
  background-color: #acb6bf;
 
  color: #000;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Quicksand";
}
.contenedorTextoSol {
  background-color: #acb6bf;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.textoSol {
  color: #000;
  font-size: 14px;
  width: 65%;
  margin-left: 35px;
  font-weight: 500;
  line-height: 25px;
}
.contenedor-video-soluciones {
  grid-column: 1/7;
  grid-row: 7;
  height: 60vh;
}
.contenedor-textnum-soluciones {
  grid-column: 7/-1;
  grid-row: 7;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tituloPorque {
  text-align: center;
  height: 7vh;
  font-family: Quicksand;
}
.contenedor2-textnum-soluciones {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.contenidoPorque {
}
.separador-soluciones2 {
  grid-column: 1/-1;
  grid-row: 8;
  height: 50px;
}

.contenedorTextoSol {
  grid-column: 7/-1;
  grid-row: 9;
}
/*todo ******************************************************************************
/*todo........... Media query para pantallas menores a 900px...................... */

@media (max-width: 950px) {
  .contenedor-video-soluciones {
    grid-column: 1/-1;
    grid-row: 7;
    height: auto;
    /* background-color: aqua; */
  }
  .contenedor-textnum-soluciones {
    grid-column: 1/-1;
    grid-row: 8;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: red; */
  }
  .contenedor2-textnum-soluciones {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  .contenidoPorque {
    margin-bottom: 20px;
  }
  .separador-soluciones2 {
    grid-column: 1/-1;
    grid-row: 9;
    height: 50px;
  }
  .titulo-contenedor-gris {
    grid-column: 1/-1;
    grid-row: 10;
    background-color: #acb6bf;
    height: auto;
    text-align: start;
    font-size: 20px;
    padding-left: 35px;
    padding-right: 15px;
  }
  .contenedorTextoSol {
    grid-column: 1/-1;
    grid-row: 11;
  }
  .textoSol {
    color: #000;
    font-size: 14px;
    width: 90%;
    text-align: start;
    height: auto;
    font-weight: 500;
    line-height: 25px;
    padding-bottom: 20px;
  }
  .contenedorTextoSol {
    background-color: #acb6bf;
    display: flex;
  
    justify-content: flex-start;
    align-items: center;
  }
}
